@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
body {
  font-family: "Open Sans", system-ui;
  font-size: 16px;
  margin: 0;
}
body p, body a {
  line-height: 160%;
  color: #111111;
  font-weight: 300;
  font-size: 1.375rem;
}
body a {
  text-decoration: none;
}
body a:hover {
  text-decoration: underline;
}

header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  z-index: 1;
}
header a {
  font-size: 1.375rem;
  color: #111111;
  font-weight: 600;
}

.main {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.main h1 {
  font-size: 4.5rem;
  line-height: 120%;
  margin-top: 160px;
  margin-bottom: 160px;
}
.main .works {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}
.main .works .delay02 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.main .works .slide {
  -webkit-animation: slide 0.6s cubic-bezier(0.2, 0.6, 0.2, 1);
  -moz-animation: slide 0.6s cubic-bezier(0.2, 0.6, 0.2, 1);
  animation: slide 0.6s cubic-bezier(0.2, 0.6, 0.2, 1);
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}
.main .works__item {
  width: calc(50% - 60px);
  margin-bottom: 100px;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
}
.main .works__item:hover {
  text-decoration: none;
}
.main .works__item:hover img {
  transform: scale(1.15);
}
.main .works__item:nth-child(2n-2) {
  position: relative;
  top: 200px;
  top: 300px;
}
.main .works__item__img {
  border-radius: 24px;
  overflow: hidden;
  height: 640px;
  transition: all 0.8s cubic-bezier(0.2, 0.6, 0.2, 1);
}
.main .works__item__img:hover {
  transform: scale(0.95);
  transition: all 0.8s cubic-bezier(0.2, 0.6, 0.2, 1);
  cursor: pointer;
}
.main .works__item__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s cubic-bezier(0.2, 0.6, 0.2, 1);
}
.main .works__item__title {
  text-align: center;
  padding-top: 24px;
}
.main .works__item__title:hover {
  cursor: pointer;
}
.main .works__item__title h3 {
  color: #111111;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 160%;
  display: block;
  text-decoration: none;
  margin-bottom: 0;
}
.main .works__item__title p {
  margin: 0;
  color: #666666;
  font-weight: 300;
  font-size: 1.125rem;
  display: block;
  text-decoration: none;
}
.main .about {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  margin-top: 11vw;
}
.main .about a {
  font-weight: 600;
}
.main .contact {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  margin-top: 11vw;
  margin-bottom: 11vw;
}
.main .contact p {
  margin: 0;
}
.main .contact a {
  font-size: 3.7vw;
  font-weight: 600;
}

.about {
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 780px;
  padding: 12vh 0;
}
.about h2 {
  color: #111111;
  font-weight: 500;
  font-size: 2.75rem;
  line-height: 120%;
  margin: 60px 0 40px 0;
}
.about .text {
  margin-bottom: 40px;
}

footer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
footer p {
  font-size: 16px;
}